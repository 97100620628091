<template>
  <layout selectedNav="typical-application">
    <div class="container">
      <div class="content" v-loading="showLoading">
        <div class="title">
          <span class="titleText">{{ form.title }}</span>
          <span class="rightContent">
            <img class="shareIcon" src="../../assets/images/share.png" />
            <span class="share">分享</span>
            <img class="bookmarkIcon" src="../../assets/images/bookmark.png" />
            <span class="bookmark">收藏</span>
          </span>
        </div>
        <div class="date">
          <div class="leftContent">
            <span class="appscene">应用场景：{{ form.scene }}</span>
            <span class="updateDate">更新日期：{{ form.updatetime }}</span>
            <span class="releaseDate">发布日期：{{ form.createtime }}</span>
          </div>
          <div class="rightContent">
            <img class="playbackIcon" src="../../assets/images/viewed.png" />
            <span class="times">{{ form.access }}浏览量</span>
            <div class="tag" :style="{ background: form.color }">
              <span class="tagContent">{{ form.datarealm }}</span>
            </div>
          </div>
        </div>
        <div class="overview">
          <span class="introduction">应用简介：</span>
          <p>
            {{ form.remark }}
          </p>
        </div>
        <div class="rotograms">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in imgUrl" :key="index"
              ><img
                :src="imgUrl[index]"
                style="object-fit:cover; width: 525px;height: 262px;"
            /></swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="article">
          <span class="introduction">应用详情：</span>
          <p>{{ form.neir }}</p>
        </div>
        <!-- <div class="dataSource">
          <span class="introduction">数据来源：</span>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="num" label="序号" width="120">
            </el-table-column>
            <el-table-column prop="name" label="数据集名称" width="360">
            </el-table-column>
            <el-table-column prop="from" label="数据来源"> </el-table-column>
            <el-table-column prop="attribute" label="开放属性">
            </el-table-column>
          </el-table>
        </div> -->
        <div class="linkContainer">
          <span class="introduction">应用链接：</span>
          <div class="link">
            <div class="urlButton">
              <span>url</span>
            </div>
            <a
              class="linkAddress"
              :href="'https://' + form.URL"
              target="_blank"
              >{{ form.URL }}</a
            >
          </div>
          <div
            class="link"
            v-for="(downloadItem, index) in fileList.list"
            :key="index"
          >
            <div class="urlButton">
              <span>附件</span>
            </div>
            <div class="linkAddress" @click="handleDownloadClick(downloadItem)">
              {{ downloadItem.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  components: {
    Layout,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      // tableData: [
      //   {
      //     num: "01",
      //     name: "经营异常企业信息",
      //     from: "上海市商场监管局",
      //     attribute: "有条件开发"
      //   },
      //   {
      //     num: "02",
      //     name: "经营异常企业信息",
      //     from: "上海市商场监管局",
      //     attribute: "有条件开发"
      //   },
      //   {
      //     num: "03",
      //     name: "经营异常企业信息",
      //     from: "上海市商场监管局",
      //     attribute: "有条件开发"
      //   }
      // ],
      form: {
        id: null,
        title: "1978年以来住宅投资和竣工建筑面积",
        access: "",
        items: [],
        FJurl: "",
        URL: "",
        createtime: "2020-11-22",
        datarealm: "",
        fjname: "",
        neir: "",
        scene: "公共安全",
        updatetime: "2020-11-22",
        yltp: "",
        remark: ""
      },
      imgUrl: "",
      createTime: "",
      showLoading: false,
      fileList: ""
    };
  },
  methods: {
    getDetail() {
      this.showLoading = true;
      this.$api.typicalApplication
        .detailPage({ id: this.form.id })
        .then(res => {
          this.showLoading = false;
          this.form = res.data[0];
          console.log(res);
          let fileUrls = res.data[0].FJurl;
          this.imgUrl = eval(this.form.yltp);
          this.fileList = {
            list: fileUrls.map(item => {
              return {
                title: item.filename,
                url: item.fileurl
              };
            })
          };
        })
        .catch(err => {
          console.log(err);
          this.showLoading = false;
        });
    },
    handleDownloadClick(item) {
      this.$api.dataSource
        .download({
          id: this.form.id
        })
        .then(data => {
          console.log(data);
          window.open(item.url);
        });
    }
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.getDetail();
  }
};
</script>
<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100%;
  .swiper-slide {
    width: 525px;
    height: 262px;
    background-image: linear-gradient(90deg, #9eceff 0%, #56a9ff 100%);
  }
}
.container {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  .content {
    margin-bottom: 20px;
    width: 1200px;
    height: 1530px;
    background: #ffffff;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 20px 0 20px;
      .titleText {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 37px;
      }
      .rightContent {
        height: 37px;
        line-height: 37px;
        position: relative;
        .shareIcon {
          position: absolute;
          right: 100px;
          top: 10px;
        }
        .share {
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #333333;
          line-height: 17px;
          margin-right: 48px;
        }
        .bookmarkIcon {
          position: absolute;
          right: 27px;
          top: 10px;
        }
        .bookmark {
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #333333;
          line-height: 17px;
        }
      }
    }
    .date {
      margin: 15px 20px 20px 20px;
      height: 30px;
      font-size: 12px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #656565;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      .leftContent {
        .updateDate {
          margin-right: 50px;
          margin-left: 50px;
        }
      }
      .rightContent {
        // position: relative;
        display: inline-block;
        line-height: 17px;
        .playbackIcon {
          display: inline-block;
          vertical-align: middle;
          // margin-right: 4px;
          // position: absolute;
          // top: 8px;
          // right: 175px;
        }
        .times {
          height: 17px;
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #656565;
          line-height: 17px;
          display: inline-flex;
          align-items: center;
          margin-left: 5px;
          img {
            margin-right: 5px;
          }
        }
        .tag {
          margin-left: 30px;
          display: inline-block;
          width: 90px;
          height: 30px;
          background: #45b5bd;
          border-radius: 2px;
          text-align: center;
          line-height: 30px;
          .tagContent {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    .overview {
      .introduction {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
      }
      p {
        text-indent: 2em;
        line-height: 35px;
        margin: 0;
      }
      margin: 0 20px;
      width: 1160px;
      // height: 100px;
      font-size: 18px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #656565;
      line-height: 35px;
    }
    .rotograms {
      margin: 23px 20px 42px 20px;
      height: 262px;
    }
    .article {
      .introduction {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
      }
      p {
        text-indent: 2em;
        line-height: 35px;
        margin: 0;
      }
      margin: 0 20px 20px 20px;
      width: 1160px;
      // height: 560px;
      font-size: 18px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #656565;
      line-height: 35px;
    }
    .dataSource {
      height: 250px;
      margin: 0 20px;
      width: 1160px;
      .introduction {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
      }
    }
    .linkContainer {
      .introduction {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
      }
      margin: 0 20px;
      width: 1160px;
      // height: 109px;
      .link {
        padding: 10px 0;
        border-bottom: 1px solid rgba(88, 131, 248, 0.5);

        .urlButton {
          margin-left: 30px;
          margin-right: 20px;
          display: inline-block;
          width: 50px;
          height: 20px;
          background: rgba(69, 181, 189, 0.1);
          border-radius: 2px;
          border: 1px solid #45b5bd;
          text-align: center;
          .triangle {
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-left: 10px solid #45b5bd;
            border-bottom: 5px solid transparent;
          }
          span {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #45b5bd;
            line-height: 20px;
          }
        }
        .linkAddress {
          display: inline;
          text-decoration: underline;
          width: 354px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #5883f8;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
